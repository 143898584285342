<template>
  <layout
    :activeIndex="activeIndex"
    :activeModel.sync="activeModel"
    :topBarItems="topBarItems"
    @setIndex="setIndex"
  >
    <template #left-0>
      <left-menu :list="list" @select="leftMenuSelect"></left-menu>
    </template>
    <template #left-1>
      <b-card>
        <b-card-title>筛选</b-card-title>
        <label>设备名称</label>
        <b-form-input
          placeholder="输入设备名称查询"
          v-model="equipFilter.equip_name"
          trim
        ></b-form-input>

        <label class="mt-1">楼层</label>
        <v-select
          append-to-body
          clearable
          multiple
          v-model="equipFilter.floor"
          :options="equipFilterOptions.floor"
          :placeholder="$t('No filter')"
          class="w-100"
        />

        <div class="text-right mt-2">
          <b-btn class="mr-1" @click="equipFilterReset">重置</b-btn>
          <b-btn variant="primary" @click="equipFilterSubmit">查询</b-btn>
        </div>
      </b-card>
    </template>
    <template #right-0></template>
    <template #right-1>
      <b-card no-body>
        <b-table
          :current-page="currentPage"
          :fields="fields"
          :items="items"
          :per-page="perPage"
          hover
          striped
          show-empty
        >
          <template v-slot:empty="scope">
            <div class="my-5">
              <h4 class="text-center">{{ $t("No Data") }}</h4>
            </div>
          </template>
        </b-table>
      </b-card>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        align="right"
        first-number
        hide-goto-end-buttons
        last-number
        next-class="next-item"
        prev-class="prev-item"
      />
    </template>
    <template #right-2>
      <panel-group :show-title="false" group-uid="aa516ecb"></panel-group>
    </template>
  </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { reactive, toRefs, ref } from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import vSelect from "vue-select";

export default {
  name: "water-alarm",
  components: {
    Layout,
    LeftMenu,
    vSelect,
    panelGroup: () => import("@/views/panel-group"),
  },
  setup() {
    // 框架相关参数
    const layoutParams = reactive({
      activeIndex: 0,
      activeModel: "",
      topBarItems: [
        {
          title: "主页",
          model: { path: "Floors/B5-F12/structure", dbIds: [] },
        },
        { title: "设备列表" },
        { title: "数据分析" },
      ],
    });
    //左侧菜单相关参数
    const leftMenuParams = reactive({
      list: [
        {
          title: "F12",
          value: 12,
          model: { path: "Floors/F12/structure", dbIds: [] },
        },
        {
          title: "F11",
          value: 11,
          model: { path: "Floors/F11/structure", dbIds: [] },
        },
        {
          title: "F10",
          value: 10,
          model: { path: "Floors/F10/structure", dbIds: [] },
        },
        {
          title: "F9",
          value: 9,
          model: { path: "Floors/F9/structure", dbIds: [] },
        },
        {
          title: "F8",
          value: 8,
          model: { path: "Floors/F8/structure", dbIds: [] },
        },
        {
          title: "F7",
          value: 7,
          model: { path: "Floors/F7/structure", dbIds: [] },
        },
        {
          title: "F6",
          value: 6,
          model: { path: "Floors/F6/structure", dbIds: [] },
        },
        {
          title: "F5",
          value: 5,
          model: { path: "Floors/F5/structure", dbIds: [] },
        },
        {
          title: "F3",
          value: 3,
          model: { path: "Floors/F3/structure", dbIds: [] },
        },
        {
          title: "F2",
          value: 2,
          model: { path: "Floors/F2/structure", dbIds: [] },
        },
        {
          title: "F1",
          value: 1,
          model: { path: "Floors/F1/structure", dbIds: [] },
        },
        {
          title: "B1",
          value: -1,
          model: { path: "Floors/B1/structure", dbIds: [] },
        },
        {
          title: "B2",
          value: -2,
          model: { path: "Floors/B2/structure", dbIds: [] },
        },
        {
          title: "B3",
          value: -3,
          model: { path: "Floors/B3/structure", dbIds: [] },
        },
        {
          title: "B5",
          value: -5,
          model: { path: "Floors/B5/structure", dbIds: [] },
        },
      ],
    });

    // 左侧菜单选中事件
    const leftMenuSelect = (event) => {
      console.log("leftMenuSelectEvent", event);
      // activeModel.value = event.model
      layoutParams.activeModel = event.model;
    };

    const setIndex = (e) => {
      layoutParams.activeIndex = e;
    };

    const data = reactive({
      index: 0,
      status: [
        {
          text: "主页",
          active: true,
        },
        {
          text: "设备列表",
          active: false,
        },
      ],
      floor_list: [
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
      fields: [
        { key: "id", label: "ID" },
        { key: "no", label: "设备编号" },
        { key: "name", label: "设备名称" },
        { key: "floor", label: "楼层" },
        { key: "status", label: "报警状态" },
        { key: "action", label: "操作" },
      ],
      items: [
        {
          id: 1,
          no: "jsf35_yg_001",
          name: "烟感报警器-001",
          floor: "F1",
          status: false,
        },
        {
          id: 1,
          no: "jsf35_yg_001",
          name: "烟感报警器-001",
          floor: "F1",
          status: false,
        },
        {
          id: 1,
          no: "jsf35_yg_001",
          name: "烟感报警器-001",
          floor: "F1",
          status: false,
        },
        {
          id: 1,
          no: "jsf35_yg_001",
          name: "烟感报警器-001",
          floor: "F1",
          status: false,
        },
        {
          id: 1,
          no: "jsf35_yg_001",
          name: "烟感报警器-001",
          floor: "F1",
          status: false,
        },
      ],
      perPage: 10,
      currentPage: 1,
      rows: 1,
    });
    /**
     * 设备列表查询
     * @type {Ref<UnwrapRef<{equip_name: string, floor: string}>>}
     */
    const equipFilter = ref({
      equip_name: "",
      floor: "",
    });
    const equipFilterOptions = ref({
      floor: [
        "RF",
        "F12",
        "F11",
        "F10",
        "F9",
        "F8",
        "F7",
        "F6",
        "F5",
        "F3",
        "F2",
        "F1",
        "B1",
        "B2",
        "B3",
        "B5",
      ],
    });

    const equipFilterSubmit = () => {
      const { equip_name, floor } = equipFilter.value;
      data.items = store.state.jsf35.equip_list.filter((item) => {
        return (
          (equip_name
            ? item.equip_name.toLowerCase().includes(equip_name.toLowerCase())
            : true) && (floor ? floor.includes(item.floor) : true)
        );
      });
      data.rows = data.items.length;
    };

    const equipFilterReset = () => {
      console.log("equipFilterReset");
      equipFilter.value = {
        equip_name: "",
        floor: "",
      };
      equipFilterSubmit();
    };
    return {
      ...toRefs(layoutParams),
      ...toRefs(leftMenuParams),
      leftMenuSelect,
      setIndex,
      ...toRefs(data),
      equipFilter,
      equipFilterOptions,
      equipFilterSubmit,
      equipFilterReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.r_bottom {
  .rb_top {
    height: 90%;

    .is_enabled {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
    }
  }

  .rb_bottom {
    height: 10%;
    display: flex;
    align-items: flex-end;
  }
}
</style>
